<template>
  <KCrudLayout
    :filters.sync="filters"
    :search.sync="searchQuery"
  >
    <template #header>
      <k-title>{{ $t('invoice.titleOverview') }}</k-title>
    </template>
    <template #filters="{ attrs, on }">
      <ConceptInvoicesFilter
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <template #view.list>
      <k-crud-table
        ref="table"
        :headers="crudHeaders"
        :index-request="index"
        language-prefix="invoice.fields"
        :params="filters"
        :search="searchQuery"
        resource="invoice"
      >
        <template #item.warning="{ item }">
          <v-tooltip
            v-if="hasNonFinalTimeRegistrations(item)"
            top
          >
            <template #activator="{ on }">
              <v-icon
                color="warning"
                v-on="on"
              >
                $triangle
              </v-icon>
            </template>
            <div v-if="item.hasUnapprovedTimeRegistrations">
              {{ $t(`invoice.messages.warnings.hasUnapprovedTimeRegistrations`) }}
            </div>
            <div v-if="item.hasConceptTimeRegistrations">
              {{ $t(`invoice.messages.warnings.hasConceptTimeRegistrations`) }}
            </div>
          </v-tooltip>
        </template>
        <template #item.invoiceTypeId="{ item: { invoiceTypeId } }">
          {{ $t(`invoice.invoiceType.${getEnumKeyByValue(invoiceType, invoiceTypeId)}`) }}
        </template>
        <template #item.amount="{ item }">
          <span v-if="!item.isProcessing">
            {{ $n((item.amount / 100), 'currency') }}
          </span>
        </template>
        <template #item.invoicePeriod="{ item: { month, week } }">
          {{ month ? $tc(`global.shortMonth.${month}`) : '' }}
          {{ week ? `${$tc('global.week')} ${week}` : '' }}
        </template>
        <template #actions="{ item }">
          <span
            v-if="item.isProcessing"
            class="text-caption text--disabled"
          >
            {{ $t('invoice.preview.isProcessing') }}
          </span>
          <k-btn
            v-else-if="!hasNonFinalTimeRegistrations(item)"
            @click.stop="handleCreateInvoice(item)"
          >
            {{ $t('actions.previewResource', { resource: $tc('invoice.title', 1) }) }}
          </k-btn>
        </template>
      </k-crud-table>
    </template>
  </KCrudLayout>
</template>

<script>
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import eventBus from '@/application/eventBus.ts';
import KTitle from '@/components/layout/KTitle.vue';
import { invoiceType } from '@/application/enums/invoiceType';
import { invoiceStatus } from '@/application/enums/invoiceStatus';
import { index } from '@/modules/invoice/api/overview.js';
import KBtn from '@/components/KButton.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { getEnumKeyByValue } from '@/application/helpers/enum.js';
import ConceptInvoicesFilter from '@/modules/invoice/components/ConceptInvoicesFilter.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';

export default {
  name: 'ConceptsTable',
  components: {
    ConceptInvoicesFilter,
    KCrudLayout,
    KBtn,
    KTitle,
    KCrudTable,
  },
  mixins: [querySyncMixin],
  data: () => ({
    // todo uncomment when implementing showFinishedPeriod filter
    //filters: { showFinishedPeriod: true },
    filters: {},
    searchQuery: '',
  }),
  computed: {
    invoiceType: () => invoiceType,
    invoiceStatus: () => invoiceStatus,
    crudHeaders() {
      return [
        {
          value: 'warning',
          text: '',
          sortable: false,
          width: '1%',
        }, {
          value: 'sender',
          language: 'invoice.crudHeaders.sender',
        }, {
          value: 'receiver',
          language: 'invoice.crudHeaders.receiver',
        }, {
          value: 'invoiceTypeId',
          language: 'invoice.crudHeaders.invoiceType',
        }, {
          align: 'end',
          value: 'amount',
          language: 'invoice.crudHeaders.amount',
        }, {
          value: 'invoicePeriod',
          language: 'invoice.crudHeaders.invoicePeriod',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [{
      exact: true,
      to: { name: 'invoice.concept.index' },
      text: this.$t('invoice.titleOverview'),
    }]);
  },
  methods: {
    getEnumKeyByValue: getEnumKeyByValue,
    hasNonFinalTimeRegistrations(item) {
      return item.hasUnapprovedTimeRegistrations || item.hasConceptTimeRegistrations;
    },
    handleCreateInvoice(item) {
      this.$router.push({ name: 'invoice.concept', params: { id: item.id } });
    },
    index(page, perPage, search, sortBy, descending, params) {
      if (sortBy === 'invoicePeriod') sortBy = 'invoicePeriodStartingDate';

      return index(page, perPage, search, sortBy, descending, params);
    },
  },
};
</script>
